'use client';

import { useMemo, useState, useEffect } from 'react';
import type {
  AccessoryProduct,
  JoinProducts,
  WhoopYourWayConfigurations,
  CartProduct,
  RadioInputOption,
  WhoopYourWayProduct,
} from 'ui';
import { isWhoopYourWay, getMembership, cltw } from 'ui';
import { Button } from 'ui/components/ButtonV2/Button';
import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from '@whoop/i18n/lang/client';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { getDeviceByType } from 'ui/utils/deviceHelpers';
import dynamic from 'next/dynamic';
import type {
  AccessoryGroup,
  JoinFlowContent,
} from 'services/generated/growth-content-service';
import clsx from 'clsx';
import CTACard from 'ui/components/CTACard/CTACard';
import LandingPageLoadingState from 'ui/components/JoinFlowShared/LandingPageLoadingState';
import { formatPriceWithSiteValues } from '@whoop/i18n/utils/priceHelpers';
import {
  getItemProperties,
  getMembershipProperties,
} from 'lib/utils/analyticsHelper';
import {
  type PersonalizationOption,
  getAllPersonalizationOptions,
  getPersonalizationSwatches,
  getPreviousSelection,
} from 'lib/utils/personalizationHelper';
import { env } from 'env';
import { useAnalytics } from '@/hooks/analytics';
import Label from '@/components/swatchSelector/label';
import LandingPageBottomCTA from '@/components/landingPage/LandingPageBottomCTA/LandingPageBottomCTA';
import SwatchSelector from '@/components/swatchSelector/swatchSelector';
import { useSiteStore } from '@/store/SiteStoreProvider';
import LandingPageHeader from '@/components/landingPage/LandingPageHeader/LandingPageHeader';
import { getMembershipFromQueryParam } from '@/lib/utils/skipMembershipSelectHelper';
import {
  getPerPersonPrice,
  getDisplayLowestPriceFamilyMembership,
} from 'lib/utils/familyHelpers';
import Cart from '@/components/cart/Cart';
import { useNavigation } from '@/hooks/navigation';
import { HeroCarousel } from '@/components/HeroCarousel/HeroCarousel';
import { useExperimentService } from '@/hooks/experimentService';
import HowItWorks from '../howItWorks/howItWorks';
import EngravingSection from '../EngravingSection/EngravingSection';
import LandingPageSubtext from '../landingPageSubtext/landingPageSubtext';
import Eyebrow from '../Eyebrow/Eyebrow';
import PhoneAndSelectedDevice from './PhoneAndSelectedDevice';
import { getLandingPageSubtextCopy } from './landingPageContentUtils';
import { featureFlagNames } from '@/client/featureFlagClient';

const WhoopYourWayModal = dynamic(
  () => import('../../accessories/WhoopYourWayModal/WhoopYourWayModal'),
  { ssr: false },
);

const EngravingModal = dynamic(
  () => import('../../accessories/EngravingModal/EngravingModal'),
  { ssr: false },
);

const WhoopAppData = dynamic(() => import('../whoopAppData/whoopAppData'));
const Gen4Data = dynamic(() => import('../gen4Data/gen4Data'));
const CoachingData = dynamic(() => import('../coachingData/coachingData'));

interface LandingPageContentProps {
  isMobile?: boolean;
  accessories?: AccessoryGroup[];
  requiredProducts?: JoinProducts | null;
  whoopYourWayConfigs?: WhoopYourWayConfigurations;
  promoContent?: JoinFlowContent;
  skipMemSelection?: string;
  skipMemFamilySize?: string | null;
  loading: boolean;
}
export default function LandingPageContent({
  isMobile,
  accessories: propAccessories,
  requiredProducts: propRequiredProducts,
  whoopYourWayConfigs: propWhoopYourWayConfigs,
  promoContent: propPromoContent,
  skipMemSelection,
  skipMemFamilySize,
  loading,
}: LandingPageContentProps): React.JSX.Element {
  const {
    accessoriesStore,
    addToCart,
    setIsCartOpen,
    cartProducts,
    currency,
    removeFromCart,
    whoopYourWayConfigsStore,
    language,
    region,
    requiredProductsStore,
    setAsAGift,
    promoContentStore,
    promoInfo,
    isRafGiftFlow,
    featureFlags,
  } = useSiteStore(
    useShallow((state) => ({
      accessoriesStore: state.accessories,
      addToCart: state.addToCart,
      setIsCartOpen: state.setIsCartOpen,
      cartProducts: state.cartProducts,
      currency: state.currency,
      removeFromCart: state.removeFromCart,
      whoopYourWayConfigsStore: state.whoopYourWayConfigs,
      language: state.language,
      region: state.region,
      asAGift: state.asAGift,
      requiredProductsStore: state.requiredProducts,
      setAsAGift: state.setAsAGift,
      promoContentStore: state.promoContent,
      promoInfo: state.promoInfo,
      isRafGiftFlow: state.isRafGiftFlow,
      featureFlags: state.featureFlags,
    })),
  );
  const { accessories, whoopYourWayConfigs, requiredProducts, promoContent } =
    useMemo(() => {
      const accessories =
        accessoriesStore?.length === 0
          ? propAccessories || []
          : accessoriesStore;
      const whoopYourWayConfigs =
        whoopYourWayConfigsStore ?? propWhoopYourWayConfigs;
      const requiredProducts =
        requiredProductsStore.devices.length === 0 && propRequiredProducts
          ? propRequiredProducts
          : requiredProductsStore;
      const promoContent = promoContentStore
        ? promoContentStore
        : propPromoContent;
      return {
        accessories,
        whoopYourWayConfigs,
        requiredProducts,
        promoContent,
      };
    }, [
      accessoriesStore,
      promoContentStore,
      propAccessories,
      propPromoContent,
      propRequiredProducts,
      propWhoopYourWayConfigs,
      requiredProductsStore,
      whoopYourWayConfigsStore,
    ]);

  const israelDisabled = featureFlags[featureFlagNames.DISABLE_IL_JF];
  const inIsraelAndDisabled = region === 'IL' && israelDisabled;

  const { t } = useTranslation('joinLandingPage', language);
  const { getExistingVariant } = useExperimentService();
  const { trackAmplitudeEvent } = useAnalytics();
  const { goTo, searchParams } = useNavigation();
  const { personalizationOptions, swatches } = useMemo(() => {
    const personalizationOptions: PersonalizationOption[] =
      getAllPersonalizationOptions(accessories ?? [], whoopYourWayConfigs);
    const swatches: RadioInputOption[] = getPersonalizationSwatches(
      personalizationOptions,
    );
    return { personalizationOptions, swatches };
  }, [accessories, whoopYourWayConfigs]);

  const { option } = getPreviousSelection(cartProducts, personalizationOptions);
  const [showEngravingModal, setShowEngravingModal] = useState<boolean>(false);
  const [showWhoopYourWay, setShowWhoopYourWay] = useState<boolean>(false);
  const [bandInCart, setBandInCart] = useState<CartProduct | undefined>();
  const [selectedOption, setSelectedOption] =
    useState<PersonalizationOption>(option);
  const [hoverOption, setHoverOption] = useState<
    PersonalizationOption | undefined
  >();
  const [src, setSrc] = useState(
    `/recovery/${language}/recovery-overview.webp`,
  );

  const isJfHeroCarouselEnabled = Boolean(isMobile && !promoInfo?.affiliate);

  const requiredDevice = useMemo(() => {
    try {
      return getDeviceByType(requiredProducts.devices, 'default');
    } catch (err) {
      // waiting for state to get set
      return undefined;
    }
  }, [requiredProducts]);

  useEffect(() => {
    if (requiredDevice) {
      addToCart(requiredDevice, 1);
    }
  }, [requiredDevice]);

  const onSwatchChange = (handle: string): void => {
    clearBandSelectionAndCart();
    const option = personalizationOptions.find(
      (option: PersonalizationOption) => option.handle === handle,
    );
    if (!option) {
      setSelectedOption(personalizationOptions[0]);
      setHoverOption(personalizationOptions[0]);
    }
    if (option?.product) {
      setSelectedOption(option);
      updateCartWithNewProduct({ item: option.product, quantity: 1 });
    }
    if (option?.handle === 'onyx') {
      setSelectedOption(option);
    }
    if (option?.handle === 'wyw') {
      trackAmplitudeEvent(AmplitudeEvents.OpenWhoopYourWayModal);
      setShowWhoopYourWay(true);
    }
  };

  const clearBandSelectionAndCart = (): void => {
    if (bandInCart) {
      trackAmplitudeEvent(AmplitudeEvents.RemovedItemFromCart, {
        ...getItemProperties(bandInCart.item),
      });
      removeFromCart(bandInCart);
      setBandInCart(undefined);
    } else if (cartProducts.length > 1) {
      const possibleBand = cartProducts.find((product) => {
        if (
          product.item.product_type === 'accessory' &&
          (product.item as AccessoryProduct).accessory_type === 'band'
        ) {
          return product;
        }
        return undefined;
      });
      if (possibleBand) {
        trackAmplitudeEvent(AmplitudeEvents.RemovedItemFromCart, {
          ...getItemProperties(possibleBand.item),
        });
        removeFromCart(possibleBand);
      }
      setBandInCart(undefined);
    }
  };

  const updateCartWithNewProduct = (cartProduct: CartProduct): void => {
    clearBandSelectionAndCart();
    setBandInCart(cartProduct);
    addToCart(cartProduct.item, cartProduct.quantity);
  };

  const onHover = (handle: string): void => {
    if (!handle) {
      setHoverOption(undefined);
    } else {
      const option = personalizationOptions.find(
        (option: PersonalizationOption) => option.handle === handle,
      );
      setHoverOption(option);
    }
  };

  const addWhoopYourWay = (
    product: WhoopYourWayProduct,
    quantity: number,
  ): void => {
    const wywOption = personalizationOptions.find(
      (option: PersonalizationOption) => option.handle === 'wyw',
    );
    wywOption && setSelectedOption(wywOption);
    updateCartWithNewProduct({ item: product, quantity });
    setShowWhoopYourWay(false);
  };

  const showCustomWywImage =
    (hoverOption || selectedOption).handle === 'wyw' &&
    bandInCart &&
    isWhoopYourWay(bandInCart.item);

  const trackOnJoin = (): void => {
    if (requiredDevice) {
      const item = bandInCart === undefined ? requiredDevice : bandInCart.item;
      trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
        ...getItemProperties(item),
      });
    }
  };

  const handleGift = (): void => {
    setAsAGift(true);
    trackOnJoin();
    goTo('membership');
  };

  const handleJoin = (): void => {
    setAsAGift(false);
    trackOnJoin();
    goTo('membership');
  };

  const checkSkipMembership = ({ gift }: { gift: boolean }): void => {
    if (skipMemSelection) {
      const familyMemberships = requiredProducts.family_memberships?.length
        ? requiredProducts.family_memberships
        : [];
      const queryRequiredMemberships = [
        ...requiredProducts.join_memberships,
        ...familyMemberships,
      ];
      const membership = getMembershipFromQueryParam(
        skipMemSelection,
        queryRequiredMemberships,
        skipMemFamilySize,
      );
      // Don't skip membership selection if choosing trial on gift flow
      if (membership) {
        addToCart(membership, 1);
        setIsCartOpen(true);
        // Separate events because item and membership have conflicting properties
        trackOnJoin();
        trackAmplitudeEvent(
          AmplitudeEvents.AddedItemToCart,
          getMembershipProperties(membership),
        );
        if (gift) setAsAGift(true);
        return;
      }
    }
    gift ? handleGift() : handleJoin();
  };

  const handleOpenEngravingModal = (): void => {
    trackAmplitudeEvent(AmplitudeEvents.ProductDetailsViewed, {
      product_handle: 'engraving',
    });
    setShowEngravingModal(true);
  };

  const trialMembership = getMembership(
    'trial',
    requiredProducts.join_memberships,
  );

  let familyMembership;
  if (
    requiredProducts.family_memberships &&
    requiredProducts.family_memberships.length > 0
  ) {
    const displayFamilyMembership = getDisplayLowestPriceFamilyMembership(
      requiredProducts.family_memberships,
    );
    if (displayFamilyMembership) {
      familyMembership = displayFamilyMembership;
    }
  }

  const landingPageSubtextCopy = getLandingPageSubtextCopy({
    hasTrialMembership: Boolean(trialMembership),
    promoContent,
    promoInfo,
    currency,
    language,
    region,
    isRafGiftFlow,
    skipMemSelection,
    searchParams,
  });

  const familyDisplayPrice = familyMembership
    ? getPerPersonPrice(familyMembership)
    : 0;
  const displayFamilyMembership = familyMembership && !isRafGiftFlow;

  const onFamilyCTACardClick = () => {
    const label = displayFamilyMembership ? 'customize' : undefined;
    trackAmplitudeEvent(AmplitudeEvents.ClickedFamilyMembership, { label });
    goTo('family');
  };

  if (loading) {
    return <LandingPageLoadingState />;
  }

  return (
    <div>
      {skipMemSelection ? (
        <Cart
          CTAButtonsVariant={
            skipMemSelection === 'trial' ? 'preferCheckout' : 'control'
          }
        />
      ) : null}
      <EngravingModal
        isOpen={showEngravingModal}
        setIsOpen={setShowEngravingModal}
      />
      {whoopYourWayConfigs && showWhoopYourWay ? (
        <WhoopYourWayModal
          isOpen={showWhoopYourWay}
          onAddToCart={addWhoopYourWay}
          setIsOpen={setShowWhoopYourWay}
          siteValues={{ currency, language, region }}
          whoopYourWayConfigs={whoopYourWayConfigs}
        />
      ) : null}
      <section
        className={cltw(
          'grid h-full min-h-[850px] grid-cols-1 md:grid-cols-2 lg:grid-cols-2',
          isJfHeroCarouselEnabled ? 'bg-white' : 'bg-gray-50',
        )}
      >
        <div
          className={cltw(
            'hidden justify-end self-start pl-8 md:flex',
            isJfHeroCarouselEnabled ? 'pt-14' : 'pt-20',
          )}
        >
          <HeroCarousel
            hoverOption={hoverOption ?? selectedOption}
            language={language}
            region={region}
            showCustomWywImage={showCustomWywImage}
            wywImages={
              (bandInCart?.item as WhoopYourWayProduct)?.cart_information.images
            }
            isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
          >
            <div className='relative aspect-square h-full w-full md:max-w-[700px]'>
              <PhoneAndSelectedDevice
                deviceImage={hoverOption?.image ?? selectedOption.image}
                setSrc={setSrc}
                showCustomWywImage={showCustomWywImage}
                src={src}
                wywImages={
                  (bandInCart?.item as WhoopYourWayProduct)?.cart_information
                    .images
                }
                isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
              />
            </div>
          </HeroCarousel>
        </div>
        <div className='w-full flex-grow basis-0 md:py-12'>
          <div className='m-auto flex w-full max-w-[500px] flex-col justify-center text-center sm:pt-5 md:m-0 md:pl-16 md:text-left'>
            <div className='px-4 pt-3 md:pt-0'>
              <LandingPageHeader />
              <LandingPageSubtext
                desktopSubtext={landingPageSubtextCopy.desktopSubtext}
                imageSrc={landingPageSubtextCopy.imageSrc}
                mobileSubtext={landingPageSubtextCopy.mobileSubtext}
              />
            </div>
            <div className='flex items-center justify-center px-4 md:hidden md:px-0'>
              <HeroCarousel
                hoverOption={hoverOption ?? selectedOption}
                isMobile
                language={language}
                region={region}
                showCustomWywImage={showCustomWywImage}
                wywImages={
                  (bandInCart?.item as WhoopYourWayProduct)?.cart_information
                    .images
                }
                isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
              >
                <div className='relative aspect-square h-full w-full'>
                  <PhoneAndSelectedDevice
                    deviceImage={hoverOption?.image ?? selectedOption.image}
                    setSrc={setSrc}
                    showCustomWywImage={showCustomWywImage}
                    src={src}
                    wywImages={
                      (bandInCart?.item as WhoopYourWayProduct)
                        ?.cart_information.images
                    }
                    isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
                  />
                </div>
              </HeroCarousel>
            </div>
            {/* Personalize section */}
            <div className='grid grid-cols-1 gap-5 px-4'>
              <div>
                <p className='experiment-uppercase experiment-tracking my-0 hidden pb-3 text-2xl font-semibold md:flex'>
                  {t('chooseWhoop4Band')}
                </p>
                <div className='hidden flex-row uppercase md:flex'>
                  <Label
                    option={hoverOption ?? selectedOption}
                    siteValues={{ currency, language, region }}
                  />
                </div>
              </div>
              <Eyebrow />
            </div>
            <div className='px-4'>
              <SwatchSelector
                currency={currency}
                language={language}
                name='landing-strap-picker'
                onChange={onSwatchChange}
                onHover={onHover}
                option={hoverOption ?? selectedOption}
                options={swatches}
                region={region}
                size={isMobile ? 'medium' : 'large'}
                useExternalState
                value={selectedOption.handle}
              />
              {/* Engraving section */}
              {skipMemSelection === 'trial' || inIsraelAndDisabled ? null : (
                <EngravingSection
                  accessories={accessories ?? []}
                  showEngraving={handleOpenEngravingModal}
                />
              )}
              <div
                className={clsx(
                  'flex flex-col justify-center gap-5 pb-5 pt-3 sm:pb-5 sm:pt-5',
                )}
              >
                {/* CTA Button */}
                {isRafGiftFlow ? null : (
                  <Button
                    className='w-full'
                    data-testid='join-now'
                    onClick={() => checkSkipMembership({ gift: false })}
                    size='medium'
                    variant='bright'
                    disabled={inIsraelAndDisabled}
                  >
                    {t('continue')}
                  </Button>
                )}
                {skipMemSelection === 'trial' ||
                skipMemSelection === 'family' ? null : (
                  <Button
                    className={`w-full ${
                      isRafGiftFlow ? '' : 'bg-transparent'
                    }`}
                    data-testid='buy-as-gift'
                    onClick={() => checkSkipMembership({ gift: true })}
                    size='medium'
                    variant={isRafGiftFlow ? 'bright' : 'primary'}
                    disabled={inIsraelAndDisabled}
                  >
                    {t('buyAsGift')}
                  </Button>
                )}
                {displayFamilyMembership && skipMemSelection !== 'family' ? (
                  <div className='m-auto'>
                    <CTACard
                      CTATestId='fp-landing-page-card'
                      iconSrc='/community-icon.svg'
                      onClick={onFamilyCTACardClick}
                      subtitles={[
                        t('familyPerPerson', {
                          lowestFamilyPrice: `${formatPriceWithSiteValues(
                            familyDisplayPrice,
                            { currency, language, region },
                            { roundUp: true },
                          )}`,
                        }),
                        t('familySaveBig'),
                      ]}
                      title={t('familyTitle')}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            {/* Sign in section */}
            {!skipMemSelection && !inIsraelAndDisabled ? (
              <div className='flex w-full flex-row justify-center gap-3 pb-5 md:pb-0'>
                <div className='experiment-uppercase experiment-bold text-sm tracking-wider'>
                  {t('alreadyAMember')}
                </div>
                <a
                  className='text-accent experiment-uppercase experiment-bold ml-1 border-none bg-none p-0 text-sm tracking-wider underline'
                  href={`${
                    env.NEXT_PUBLIC_UPGRADE_URL
                  }/${language}-${region.toLowerCase()}/upgrade`}
                  rel='noreferer noopener'
                  target='_blank'
                  type='button'
                >
                  {t('getYourWhoop4')}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <HowItWorks isJfHeroCarouselEnabled={isJfHeroCarouselEnabled} />
      <Gen4Data />
      <WhoopAppData />
      <CoachingData />
      {!inIsraelAndDisabled && (
        <LandingPageBottomCTA
          onClick={() => checkSkipMembership({ gift: false })}
          showTrialsDisclaimer={Boolean(trialMembership)}
        />
      )}
    </div>
  );
}
