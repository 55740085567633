import type {
  AccessoryGroup,
  AccessoryProduct,
} from 'services/generated/growth-content-service';
import { i18n } from '@whoop/i18n/lang/client';
import {
  isAccessory,
  type CartProduct,
  type RadioInputOption,
  type WhoopYourWayConfigurations,
  isWhoopYourWay,
} from 'ui';

export interface PersonalizationOption {
  display_price?: number;
  handle: string;
  image: string;
  included?: boolean;
  label: string;
  product?: AccessoryProduct;
  swatchImage: string;
  percent_discount?: number | null;
}

export const getAllPersonalizationOptions = (
  accessories: AccessoryGroup[],
  whoopYourWayConfigs?: WhoopYourWayConfigurations,
): PersonalizationOption[] => {
  const bandGroups = accessories.filter(
    (item) =>
      item.handle === 'superknit-band-parent' ||
      item.handle === 'superknit-luxe-band-parent' ||
      item.handle === 'sportflex-band',
  );
  const colorVariants = bandGroups.flatMap((band) => band.colors);
  const bandProducts = bandGroups
    .flatMap((group) => group.products)
    .filter(
      (product) => (product.inventory_information?.max_quantity ?? 0) > 0,
    );

  const options: PersonalizationOption[] = bandProducts.map((product) => {
    const productVariant = colorVariants.find(
      (color) => color.handle === product.variant_information.color,
    );
    return {
      display_price: product.display_price,
      handle: productVariant?.handle ?? '',
      image: product.cart_information.image,
      label: product.cart_information.description,
      percent_discount: product.percent_discount,
      product,
      swatchImage: productVariant?.image ?? '',
    };
  });

  const includedOption: PersonalizationOption = {
    handle: 'onyx',
    image: '/onyxStrap.webp',
    included: true,
    label: i18n.t('joinLandingPage:onyx'),
    swatchImage:
      'https://cdn.shopify.com/s/files/1/1040/0152/files/onyx_64x64.png?v=1698244400.429',
  };

  const allOptions = [includedOption, ...options];

  if (whoopYourWayConfigs) {
    allOptions.push({
      handle: 'wyw',
      image: '/wyw_flash.gif',
      label: i18n.t('joinLandingPage:whoopYourWay'),
      swatchImage: '/wyw_pencil.svg',
    });
  }

  return allOptions;
};

export const getPersonalizationSwatches = (
  options: PersonalizationOption[],
): RadioInputOption[] => {
  return options.map((option) => {
    return {
      label: option.label,
      style: {
        background: `url(${option.swatchImage})  0% 0% / cover`,
      },
      value: option.handle,
    };
  });
};

export const getPreviousSelection = (
  cartProducts: CartProduct[],
  personalizationOptions: PersonalizationOption[],
): { cartProduct?: CartProduct; option: PersonalizationOption } => {
  const band = cartProducts.find((product) => {
    return (
      isAccessory(product.item) &&
      (product.item as AccessoryProduct).accessory_type === 'band'
    );
  });
  if (band) {
    const option = personalizationOptions.find((option) => {
      return (
        option.handle ===
        (band.item as AccessoryProduct).variant_information.color
      );
    });
    return {
      cartProduct: option ? band : undefined,
      option: option ?? personalizationOptions[0],
    };
  }
  const wyw = cartProducts.find((product) => isWhoopYourWay(product.item));
  if (wyw) {
    const option = personalizationOptions.find(
      (option) => option.handle === 'wyw',
    );
    return {
      cartProduct: option ? wyw : undefined,
      option: option ?? personalizationOptions[0],
    };
  }
  return {
    option: personalizationOptions[0],
  };
};
