import type {
  PaymentRequestOptions,
  PaymentRequestShippingAddress,
} from '@stripe/stripe-js';
import type { Currency } from '@whoop/i18n/types/internationalization';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import type { Address } from 'services/generated/order-service';
import type {
  CartProduct,
  CreatedOrder,
  OrderTotal,
  PromoCodeState,
  ShippingOption,
} from '../types';
import type { ParsedCartDTO } from '../types/cartTypes';
import {
  getTotalsFromCartProducts,
  getTotalsFromPendingOrder,
} from './totalsHelpers';

export const getPaymentRequestOptions = (
  order: CreatedOrder,
  currency: Currency,
  shipping: ShippingOption,
): PaymentRequestOptions => {
  const displayTotals = getTotalsFromPendingOrder(order, shipping);
  const total = displayTotals.pop();

  const displayItems = displayTotals.map((item) => ({
    label: item.label,
    amount: item.display_amount,
  }));

  return {
    country: 'US', // company's country
    currency, // order's currency
    total: {
      label: total?.label ?? '',
      amount: total?.display_amount ?? 0,
    },
    displayItems,
    requestPayerName: true,
    requestPayerEmail: true,
    requestPayerPhone: false,
    requestShipping: false,
  };
};

export const getPaymentRequestOptionsV2 = (
  cart: ParsedCartDTO,
  currency: Currency,
): PaymentRequestOptions => {
  const total = cart.billingDetails.find((item) => item.type === 'TOTAL');

  const displayItems = cart.billingDetails
    // Filter out items without value and the total item
    .filter((item) => Boolean(item.value) && item.type !== 'TOTAL')
    .map((item) => {
      // @ts-expect-error Filtered above.
      const formattedValue = item.value.centAmount;
      return {
        label: item.title,
        amount: formattedValue,
      };
    });

  return {
    country: 'US', // company's country
    currency, // order's currency
    total: {
      label: total?.title ?? '',
      amount: total?.value?.centAmount ?? 0,
    },
    displayItems,
    requestPayerName: true,
    requestPayerEmail: true,
    requestPayerPhone: false,
    requestShipping: false,
  };
};

export interface OneTouchDigitalWalletOptions {
  initialTotals: OrderTotal[];
  pendingOrder?: CreatedOrder;
  shippingOption?: ShippingOption;
  currency: Currency;
  cartProducts: CartProduct[];
  promoCode: PromoCodeState | undefined;
  promoInfo: CheckoutPromo | undefined;
  stripeCountry: string;
}

export const getOneTouchDigitalWalletOptions = ({
  initialTotals,
  pendingOrder,
  cartProducts,
  shippingOption,
  currency,
  promoCode,
  promoInfo,
  stripeCountry,
}: OneTouchDigitalWalletOptions): PaymentRequestOptions => {
  const displayItems: OrderTotal[] = pendingOrder
    ? getTotalsFromPendingOrder(pendingOrder, shippingOption)
    : getTotalsFromCartProducts({
        initialTotals,
        cartProducts,
        currency,
        promoCode,
        promoInfo,
      });

  const totals: OrderTotal | undefined = displayItems.find((item) => {
    return item.type === 'total';
  });

  /**
   * Remove the last item from the displayItems array as it is the total
   */
  const items = displayItems
    .filter((item) => item.type !== 'total')
    .map((item) => ({
      label: item.label,
      amount: item.display_amount,
    }));

  return {
    country: stripeCountry,
    currency,
    total: {
      label: totals?.label ?? '',
      amount: totals?.display_amount ?? 0,
    },
    displayItems: items,
  };
};

export interface OneTouchDigitalWalletOptionsV2 {
  currency: Currency;
  cart: ParsedCartDTO;
  stripeCountry: string;
}

export const getOneTouchDigitalWalletOptionsV2 = ({
  cart,
  currency,
  stripeCountry,
}: OneTouchDigitalWalletOptionsV2): PaymentRequestOptions => {
  const total = cart.billingDetails.find((item) => item.type === 'TOTAL');

  const displayItems = cart.billingDetails
    // Filter out items without value and the total item
    .filter((item) => Boolean(item.value) && item.type !== 'TOTAL')
    .map((item) => {
      // @ts-expect-error Filtered above.
      const formattedValue = item.value.centAmount;
      return {
        label: item.title,
        amount: formattedValue,
      };
    });

  return {
    country: stripeCountry,
    currency,
    total: {
      label: total?.title ?? '',
      amount: total?.value?.centAmount ?? 0,
    },
    displayItems,
  };
};

export const mapStripeAddressToWhoopAddress = (
  stripeAddress: PaymentRequestShippingAddress | undefined,
  phoneNumber: string | undefined,
  payerName: string | undefined,
): Address | undefined => {
  if (!stripeAddress) {
    return undefined;
  }
  const { country, city, postalCode, addressLine, region, recipient } =
    stripeAddress;

  if (!recipient) {
    return undefined;
  }

  const names = separateNames(payerName);

  if (!names) {
    return undefined;
  }

  return {
    first_name: names.firstName,
    last_name: names.lastName,
    phone: phoneNumber,
    country,
    city,
    line1: addressLine?.[0] || '1325 Boylston Street',
    line2: addressLine?.[1] || '',
    postal_code: postalCode,
    state: region,
    province: region,
  };
};

export const separateNames = (recipientName: string | undefined) => {
  if (!recipientName) {
    return;
  }
  const names = recipientName.split(' ');

  let firstName, lastName;
  if (names.length > 1) {
    firstName = names.slice(0, names.length - 1).join(' ');
    lastName = names[names.length - 1];
  } else {
    firstName = names[0] || ' ';
    lastName = ' ';
  }
  return {
    firstName,
    lastName,
  };
};
